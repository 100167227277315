import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { getReadableDate } from '@/utility/date'
import BettingAsianHandicap from './BettingAsianHandicap'
import BettingOverUnder from './BettingOverUnder'
import BettingMatchWinnter from './BettingMatchWinnter'
import { ROUTES } from '@/resources/routes-constants'
import { IBettingOdd } from '@/types/app-type'

interface TournamentProps {
  name: string;
  bettingOdd: IBettingOdd;
}

const BettingMatch = ({ name, bettingOdd }: TournamentProps) => {
   return (
    <>
      <div className="mt-4">
        <div className="pl-2.5 py-1.5 border-l-4 border-secondary bg-[#edf2f7] flex items-center justify-between">
          <div>
            <Link className="text-primary hover:text-red text-xs font-bold" to={ROUTES.TOURNAMENT_BETTING_ODDS.replace(':id', bettingOdd.slug)}>
              {name}
            </Link>
          </div>
          <div className="clear-both"></div>
        </div>
        {Object.entries(bettingOdd.matches).map((match) => (
          <div key={match[1].bet[0]?.id}>
            <div className="flex items-center text-xs py-1.5 border-b border-[#eee] last-of-type:border-b-0">
              <div className="w-[100px] text-red">{getReadableDate(match[1].date)}</div>
              <div className="w-[calc(100%-300px)]">
                <div className="w-1/4 py-2 flex justify-start gap-1">
                  <img className="w-5" src={match[1].teams.home.logo} alt="" />
                  <span>{match[1].teams.home.name}</span>
                </div>
                <div className="w-1/4 flex justify-start gap-1">
                  <img className="w-5" src={match[1].teams.away.logo} alt="" />
                  <span>{match[1].teams.away.name}</span>
                </div>
              </div>
              <BettingAsianHandicap bet={match[1].bet}/>
              <BettingOverUnder bet={match[1].bet}/>
              <BettingMatchWinnter bet={match[1].bet}/>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default memo(BettingMatch)
