import React, { useEffect, useState } from 'react'
import Default from '@/layouts/Default'
import { Helmet } from 'react-helmet'
import Tournament from '@/components/Tournament'
import { getFixtures, getMetaData } from '@/resources/api-constants'
import { ILeagueMatches, PaginationResponse } from '@/types/app-type'
import { getPrevDate, getPrevDateWithYear } from '@/utility/date'
import { useAppDispatch } from '@/store/reducers/store'
import { loadingAction } from '@/store/slice/loading.slice'
import { useLocation, useParams } from 'react-router-dom'
import { getFixturePredictions } from '@/resources/api-constants'

const Predictions: React.FC = () => {
  const dispatch = useAppDispatch()
  const [leagues, setLeagues] = useState<ILeagueMatches | null>(null)
  const [date, setDate] = useState<string>(getPrevDateWithYear(0))
  const { id } = useParams()
  const location = useLocation()

  const fetchMetaData = async () => {
    try {
      const slug = id ?? ''
      const result = await getMetaData({ slug, url: location.pathname, type: 'predictions' })
    } catch (error) {
      console.log(error)
    }
  }

  const dates = [
    {
      label: 'Hôm nay',
      value: getPrevDateWithYear(0)
    },
    {
      label: 'Ngày mai',
      value: getPrevDateWithYear(-1)
    }
  ]

  useEffect(() => {
    fetchData(date)
  }, [date])

  const fetchData = async (_date: string) => {
    dispatch(loadingAction.show())
    try {
      const result = await getFixturePredictions({ date: _date })

      setLeagues(result)
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  return (
    <Default>
      <Helmet>
        <title>Dự đoán bóng đá</title>
      </Helmet>

      {dates.map((_date: { label: string; value: string }) => (
        <span key={_date.value}>
          {_date.value === date ? (
            <span className="px-3 py-1 bg-red rounded text-white  hover:cursor-pointer text-xs mr-2" onClick={() => setDate(_date.value)}>
              {_date.label}
            </span>
          ) : (
            <span className="px-3 py-1 bg-secondary rounded text-primary  hover:cursor-pointer text-xs mr-2" onClick={() => setDate(_date.value)}>
              {_date.label}
            </span>
          )}
        </span>
      ))}

      <div className="bg-[#f6fbff] text-[12px] p-3 text-[#333] *:mb-2">
        <p>
          Dự đoán hôm nay và ngày mai của chuyên gia và siêu máy tính: Dự đoán kết quả tỷ số bóng đá các trận thi đấu tối và đêm nay. Cập nhật tỷ lệ, soi kèo,
          dự đoán đội hình dự kiến nhanh và chính xác nhất các giải bóng đá hàng đầu thế giới như: Ngoại Hạng Anh (NHA), La Liga (VĐQG Tây Ban Nha-TBN), Ligue 1
          (VĐQG Pháp), Serie A (VĐQG Italia-Ý), Bundesliga (VĐQG Đức) và giải V-League của Việt Nam-VN. Siêu máy tính dự đoán kết quả bóng đá Anh Đức Pháp Ý TBN
          và Việt Nam CHÍNH XÁC.
        </p>
        <p>
          Dự đoán bóng đá Anh, Đức, Pháp, Ý và TBN tối nay NHANH và SỚM nhất, chuyên gia dự đoán tỷ số giải đấu World Cup WC Euro Copa America và CAN Cup chuẩn
          xác. Chuyên gia dự đoán kết quả bóng đá CHÍNH XÁC nhất. Các nhận định và dự đoán bởi các chuyên gia bóng đá hàng đầu trong nước và thế giới và siêu
          máy tính NHANH nhất thế giới.
        </p>
        <p>
          Chuyên gia dự đoán kết quả các giải bóng đá hàng đầu Thế Giới-Châu Á-Châu Âu-Nam Mỹ-Bắc Trung Mỹ (Concacaf)-Châu Phi và Châu Đại Dương. Siêu máy tính
          dự đoán tỷ số các giải Cúp C1-C2-C3 Châu Âu-Châu Á-Nam Mỹ và Châu Phi. Chuyên gia dự đoán bóng đá các trận đấu cấp ĐTQG (đội tuyển quốc gia) của U23
          Việt Nam và ĐTQG Việt Nam (ĐT Việt Nam) ở các giải AFF Cúp-Asian Cup-SEA Games ở giải-cúp vô địch các quốc gia Đông Nam Á.
        </p>
      </div>
      {leagues &&
        Object.entries(leagues).map((item, index) => {
          return <Tournament key={index} league={item[1].league} country={item[1].country} name={item[0]} matches={item[1].items} isShowPrediction={true} />
        })}
    </Default>
  )
}

export default Predictions
