import React, { useEffect } from 'react';
import {  useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

interface Props {
  children?: React.ReactNode;
}
const RedirectGuard = ( {children}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/redirect.json`)
      .then((r) => r.json())
      .then((data) => {
        const today = format(new Date(), 'yyyy-MM-dd');

        data.map((item: any) => {
          if (item?.start_at <= today && item?.end_at >= today && location.pathname === item?.origin_link) {
            return navigate(item?.redirect_link)
          }
      })
    }).catch(() => {
      // Do nothing
    })
  }, [location, navigate])
  return <>{children}</>
}

export default RedirectGuard