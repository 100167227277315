import Default from '@/layouts/Default'
import { getMatchDetail, getBookmakers } from '@/resources/api-constants'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useLocation, useParams } from 'react-router-dom'
import { loadingAction } from '@/store/slice/loading.slice'
import { useAppDispatch } from '@/store/reducers/store'
import { IBookmaker } from '@/types/app-type'
import BettingOddTable from '@/components/MatchDetail/BettingOddTable'
import { isEmpty } from 'lodash'
import { fetchMetaData } from '@/store/slice/metadata.slice'
import MatchEvent from '@/components/MatchDetail/MatchEvent'
import LineUp from '@/components/MatchDetail/LineUp'
import Statistics from '@/components/MatchDetail/Statistics'
import { ROUTES } from '@/resources/routes-constants'

const MatchDetail = () => {
  const { id, date } = useParams()
  const dispatch = useAppDispatch()
  const [match, setMatch] = useState<any>(null)
  const [bookmakers, setBookmakers] = useState<IBookmaker[]>([])
  const [bookmakerId, setBookmakerId] = useState<number>()
  const location = useLocation()

  const fetchMeta = async () => {
    try {
      const slug = id ?? ''

      dispatch(fetchMetaData({ type: 'fixture', slug: slug, url: location.pathname }))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchBookmakers()
    fetchMeta()
  }, [id, date])

  useEffect(() => {
    fetchDetetail()
  }, [bookmakerId])

  const fetchDetetail = async () => {
    dispatch(loadingAction.show())
    try {
      if (bookmakerId) {
        const result = await getMatchDetail({ bookmaker_id: bookmakerId }, id, date)

        if (!isEmpty(result.data)) {
          setMatch(result.data)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  const fetchBookmakers = async () => {
    dispatch(loadingAction.show())
    try {
      const result = await getBookmakers()

      if (result.data.length > 0) {
        setBookmakers(result.data)
        setBookmakerId(result.data[0]?.id)
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  const matchScores = (index: number): string => {
    let home = 0;
    let away = 0;
    const scores: string[] = [];

    match.events.map((event: any) => {
      if (event.type === 'Goal') {
        if (event.team.id === match.teams.home.id) {
          home++
        }
        else {
          away++
        }
      }

      scores.push(`${home}-${away}`)
    })

    return scores[index]
  }

  return (
    <Default>
      <Helmet>
        <title>Nhận định bóng đá hôm nay</title>
      </Helmet>
      {/*bookmakers && (
        <div className="text-right mt-5">
          <select
            name="bookmaker_id"
            className="border-2 border-primary p-2 text-left w-[200px]"
            onChange={(e: any) => {
              setBookmakerId(e.target.value)
            }}
          >
            {Object.entries(bookmakers).map((bookmaker: any) => {
              return (
                <option key={bookmaker[1].id} value={bookmaker[1].id}>
                  {bookmaker[1].name}
                </option>
              )
            })}
          </select>
        </div>
      )*/}
      {match && match !== undefined && (
        <>
          <div className="p-2.5 bg-[#edf2f7] my-2 text-center mb-5">
            <a className="font-bold text-[#009246] inline-flex text-xl" href="#">
              <img className="w-6 mr-2" src={match.league?.logo} alt={match.league?.name} />
              <Link className="text-primary hover:text-red" to={ROUTES.TOURNAMENT_RESULTS.replace(':id', match.league_slug)}>
                Kết quả bóng đá {match.league?.name}
              </Link>
            </a>
            <p className="text-[#656565]">{match.date_string}</p>
            <p className="text-[#ce2b37] text-2xl font-bold">{match.time_string}</p>
          </div>
          <div className="grid grid-flow-col gap-4 text-center mb-3">
            <div className="col-auto">
              <img className="m-auto" src={match.teams.home.logo} alt={match.teams.home.name} />
              {match.team_home_slug && match.team_home_type==0 && (
                <Link className="m-3 mb-0 hover:text-red text-[#009246] font-bold" to={ROUTES.CLUB_PAGE.replace(':id', match.team_home_slug)}>
                  {match.teams.home.name}
                </Link>
              )}
              {match.team_home_slug && match.team_home_type==1 && (
                <Link className="m-3 mb-0 hover:text-red text-[#009246] font-bold" to={ROUTES.NATIONAL_TEAM.replace(':id', match.team_home_slug)}>
                  {match.teams.home.name}
                </Link>
              )}
              {match.goals?.home !== null && <div className="text-center text-[#ce2b37] text-2xl font-bold">{match.goals?.home}</div>}
            </div>
            <div className="col-auto *:mb-5">
              {(match.status.short === '1H' || match.status.short === '2H') ? (
                <div className="flex justify-center">
                  <img className="mr-2 w-[8px] h-[8px] relative top-[12px]" src="https://static.demo.kqbd.ai/flash_new.gif" alt="Live icon" />
                  <p className="text-[#ce2b37] text-2xl font-bold">{match.status.elapsed}</p>
                </div>
              ) : (
                <p className="text-[#ce2b37] text-2xl font-bold">{match.status.long}</p>
              )}
              <p>
                <button className="pb-1 pl-4 pr-4 btn bg-[#E53935] text-white rounded-[32px]">Đặt cược</button>
              </p>
            </div>
            <div className="col-auto">
              <img className="m-auto" src={match.teams.away.logo} alt={match.teams.away.name} />
              {match.team_away_slug && match.team_away_type==0 && (
                <Link className="m-3 mb-0 hover:text-red text-[#009246] font-bold" to={ROUTES.CLUB_PAGE.replace(':id', match.team_away_slug)}>
                  {match.teams.away.name}
                </Link>
              )}
              {match.team_away_slug && match.team_away_type==1 && (
                <Link className="m-3 mb-0 hover:text-red text-[#009246] font-bold" to={ROUTES.NATIONAL_TEAM.replace(':id', match.team_away_slug)}>
                  {match.teams.away.name}
                </Link>
              )}
              {match.goals?.away !== null && <div className="text-center text-[#ce2b37] text-2xl font-bold">{match.goals?.away}</div>}
            </div>
          </div>
          {match.betting_odd && <BettingOddTable bettingOdd={match.betting_odd} />}
          <div className="*:border-t *:border-[#e5e5e5] *:text-[#009246] *:font-bold *:p-2 mt-5">
            <div>
              <a href="/">Kết quả bóng đá hôm nay</a>
            </div>
            <div>
              <a href="/">Kết quả bóng đá hôm qua</a>
            </div>
          </div>
          <div className="bg-[#edf2f7] text-center p-3 mb-3 text-[#656565] font-bold border-b border-t border-[#e5e5e5] text-sm">
            Thông tin trận đấu {match.teams.home.name} vs {match.teams.away.name}
          </div>
          <div className="">
            <span className="font-bold pl-3">Sân vận động:</span> {match.venue.name}, {match.venue.city}
          </div>
          <div className="">
            <span className="font-bold pl-3">Thời tiết và Nhiệt độ trên sân:</span> -
          </div>

          {match.score?.halftime?.home !== null &&
            <div className="bg-[#edf2f7] text-center p-3 mt-3 mb-5 text-[#656565] font-bold border-b border-t border-[#e5e5e5] text-sm">
              Tỉ số Hiệp 1: {match.score?.halftime.home} - {match.score?.halftime.away}
            </div>
          }

          {!isEmpty(match.events) && (
            <>
              <div className="bg-[#edf2f7] text-left p-3 mt-3 text-[#656565] font-bold border-b border-t border-[#e5e5e5]">
                {match.teams.home.name} vs {match.teams.away.name}: Diễn biến chính
              </div>
              {match.events.map((event: any, index: number) => {
                return <MatchEvent key={index} event={event} teams={match.teams} score={matchScores(index)}/>
              })}
            </>
          )}

          {!isEmpty(match.lineups) && (
            <LineUp lineups={match.lineups} teams={match.teams} homeFormationGrid={match.home_formation_grid} awayFormationGrid={match.away_formation_grid} />
          )}

          {!isEmpty(match.statistics) && (
            <>
              <div className="bg-[#edf2f7] text-center p-3 mt-3 text-[#656565] font-bold border-b border-t border-[#e5e5e5] text-sm">
                {match.teams.home.name} vs {match.teams.away.name}: Số liệu thống kê
              </div>
              <div className="bg-[#edf2f7] grid grid-flow-col *:col-auto gap-4 text-center p-2 text-[#656565] font-bold border-b border-[#e5e5e5] text-sm">
                <div>{match.teams.home.name}</div>
                <div>{match.teams.away.name}</div>
              </div>
              <Statistics statistics={match.statistics} teams={match.teams} />
            </>
          )}

          {/*<div className="text-xs p-2.5 bg-[#f6fbff] my-2">
            Kết quả trận {match.teams.home.name} vs {match.teams.away.name} trực tuyến hôm nay lúc 14/08/2024 22:59 NHANH và CHÍNH XÁC. Xem lịch thi đấu-KQ tỷ số FK
            Rigas Futbola skola vs UE Santa Coloma: xem tường thuật trực tiếp diễn biến mới nhất của trận đấu trong khuôn khổ giải Cúp C2 Châu Âu vòng Qual.3
            bắt đầu lúc: 14/08/2024 22:59 sẽ được cập nhật liên tục 24h (LIVE) trong suốt thời gian trận đấu diễn ra. Tường thuật trực tiếp diễn biến chính, cầu
            thủ nào ghi bàn-thống kê tỷ lệ cầm bóng, số cú sút nguy hiểm và bdkq tỷ số hiệp 1 (H1), hiệp 2 (H2) và cả trận (tỷ số full-time FT). Kết quả bóng đá
            trực tuyến hôm nay Cúp C2 Châu Âu (chiều, tối và đêm nay hn): xem trực tiếp kqbd Châu Âu hôm nay và ngày mai. Tổng hợp kết quả bóng đá Châu Âu hôm
            nay: xem kqbd Cúp C2 Châu Âu TT tối đêm qua và rạng sáng nay mới nhất. Trực tiếp bóng đá Châu Âu hôm nay: link xem trực tiếp FK Rigas Futbola skola
            đấu với UE Santa Coloma vào lúc 22:59 14/08/2024. Ket qua bong da hom nay Cúp C2 Châu Âu: xem kqbd cup-c2-chau-au toi va dem nay, kq FK Rigas
            Futbola skola vs UE Santa Coloma online MOI NHAT va CHINH XAC. Cập nhật đội hình thi đấu chính thức (ra sân) và đội hình dự bị của trận đấu, sơ đồ
            thi đấu và huấn luyện viên của 2 đội. Kết quả bóng đá hôm nay Cúp C2 Châu Âu vòng Qual.3 ngày 14/08/2024 trận đấu giữa FK Rigas Futbola skola đối
            đầu với UE Santa Coloma: xem trực tiếp kqbd hn, tỷ số bóng đá Châu Âu các trận bóng đá sẽ thi đấu tối, đêm nay và rạng sáng mai. Xem kết quả Cúp C2
            Châu Âu tối và đêm nay: kqbd trực tuyến ngày 14/08/2024, tổng hợp kq bd hn MỚI NHẤT và CHÍNH XÁC.
          </div>*/}
        </>
      )}
    </Default>
  )
}

export default MatchDetail
