import Default from '@/layouts/Default'
import { login } from '@/resources/api-constants'
import { ROUTES } from '@/resources/routes-constants'
import { useAppDispatch, useAppSelector } from '@/store/reducers/store'
import { loadingAction } from '@/store/slice/loading.slice'
import { userAction } from '@/store/slice/user.slice'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

interface IFormInput {
  email: string
  password: string
}

const Login = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.user.user)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>()

  const onSubmit = async (data: IFormInput) => {
    dispatch(loadingAction.show())
    try {
      const result = await login(data.email, data.password)
      dispatch(userAction.setUser(result))
      localStorage.setItem('access_token', result.access_token)
      navigate(ROUTES.HOMEPAGE_ROUTE)
      toast.success('Login successful!')
    } catch (error) {
      toast.error('Incorrect email or password!')
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  useEffect(() => {
    if (user) {
      navigate(ROUTES.HOMEPAGE_ROUTE)
    }
  }, [user])

  return (
    <Default>
      <div>
        <div className="py-2.5 pl-1 my-2.5 bg-[#f9f9f9] border border-[#eee]">
          <h1 className="text-sm font-bold text-red">Login</h1>
        </div>
        <div className="py-2.5 pl-4 pr-4 my-6 w-full max-w-md m-[auto] bg-[#f9f9f9] border border-[#eee]">
          <form onSubmit={handleSubmit(onSubmit)} className="py-2.5 pl-1 my-2.5  flex flex-col ">
            <label htmlFor="email">Email: </label>
            <input
              {...register('email', {
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Your email invalid.'
                }
              })}
              type="text"
              id="email"
              className="mt-1 border border-[#ccc] p-2 rounded-lg focus-visible:outline-[#3c765f]"
              name="email"
              placeholder="email.example@gmail.com"
            />
            {errors.email && (
              <p className="text-xs text-red mb-4">
                {errors?.email?.type === 'required' && <span role="alert">Your email is required.</span>}
                <span role="alert">{errors.email.message}</span>
              </p>
            )}

            <label htmlFor="password">Password: </label>
            <input
              {...register('password', { required: true })}
              type="password"
              id="password"
              className="mt-1 border border-[#ccc] focus-visible:outline-[#3c765f] p-2 rounded-lg"
              name="password"
              placeholder="Your password"
            />
            {errors.password && (
              <p className="text-xs text-red mb-4">
                {errors?.password?.type === 'required' && <span role="alert">Password is required.</span>}
                <span role="alert">{errors.password.message}</span>
              </p>
            )}

            <button
              type="submit"
              value="Submit"
              className="mt-6 uppercase bg-primary hover:bg-secondary text-white hover:text-primary font-bold text-[13px] border border-[#ccc] p-2 rounded-lg"
            >
              Sign in
            </button>
          </form>
        </div>
      </div>
    </Default>
  )
}

export default Login
