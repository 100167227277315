import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getFixturesByCountry, getFixturesByTeamOrLeague } from '@/resources/api-constants'
import { ILeagueMatches } from '@/types/app-type'
import { useAppDispatch } from '@/store/reducers/store'
import { loadingAction } from '@/store/slice/loading.slice'
import { useLocation, useParams } from 'react-router-dom'
import Tournament from '@/components/Tournament'
import { fetchMetaData } from '@/store/slice/metadata.slice'

const Result: React.FC = () => {
  const [isLoadMore, setIsLoadMore] = useState(true)
  const [page, setPage] = useState(1)
  const [fixtures, setFixtures] = useState<ILeagueMatches | null>(null)
  const [leagues, setLeagues] = useState<ILeagueMatches | null>(null)
  // Page type 1 is National page, 0 is Club page
  const [pageType, setPageType] = useState(1)
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const location = useLocation()

  const fetchMeta = async () => {
    try {
      const slug = (id ?? '').replace('-football', '')
      const type = id?.includes('-football') ? 'country' : 'league'

      dispatch(fetchMetaData({ slug, url: location.pathname, type }))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchMeta()
  }, [])

  useEffect(() => {
    dispatch(loadingAction.show())
    setPage(1)
    setFixtures(null)
    setLeagues(null)
    if (!id?.includes('-football')) {
      setPageType(0)
      fetchFixturesByTeamOrLeague(1)
    } else {
      setPageType(1)
      fetchData(1)
    }
  }, [id])

  const fetchFixturesByTeamOrLeague = async (page: number) => {
    try {
      if (!id) {
        return
      }
      const teamSlug = id.includes('-football') ? id.replace('-football', '') : id
      const result = await getFixturesByTeamOrLeague({ slug: teamSlug, status: 2, page, perPage: 15 })
      // if (!result.status) {
      //   navigate(ROUTES.HOMEPAGE_ROUTE)
      // }
      if (Object.entries(result.data).length < 15) {
        setIsLoadMore(false)
      }
      if (fixtures) {
        setFixtures({ ...fixtures, ...result.data })
      } else {
        setFixtures(result.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  const fetchData = async (page: number) => {
    try {
      if (id) {
        const teamSlug = id.includes('-football') ? id.replace('-football', '') : id
        const result = await getFixturesByCountry({ countrySlug: teamSlug, status: 2, page })

        if (Object.entries(result.data).length < 15) {
          setIsLoadMore(false)
        }
        if (leagues) {
          setLeagues({ ...leagues, ...result.data })
        } else {
          setLeagues(result.data)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  return (
    <div>
      {pageType === 1 && leagues && (
        <InfiniteScroll
          style={{
            height: 'unset',
            overflow: 'unset'
          }}
          hasMore={isLoadMore}
          loader={<p>Loading...</p>}
          next={() => {
            setPage((prev) => prev + 1)
            fetchData(page + 1)
          }}
          dataLength={Object.entries(leagues).length}
        >
          {Object.entries(leagues).map((item, index) => {
            return <Tournament key={item[0]} league={item[1].league} country={item[1].country} name={item[0]} matches={item[1].items} />
          })}
        </InfiniteScroll>
      )}
      {pageType === 0 && fixtures && (
        <InfiniteScroll
          style={{
            height: 'unset',
            overflow: 'unset'
          }}
          hasMore={isLoadMore}
          loader={<p>Loading...</p>}
          next={() => {
            setPage((prev) => prev + 1)
            fetchFixturesByTeamOrLeague(page + 1)
          }}
          dataLength={Object.entries(fixtures).length}
        >
          {Object.entries(fixtures).map((item, index) => {
            return <Tournament key={item[0]} league={item[1].league} country={item[1].country} name={item[0]} matches={item[1].items} />
          })}
        </InfiniteScroll>
      )}
    </div>
  )
}

export default Result
