import { IMatch } from '@/types/app-type'
import { Screen } from '@carbon/icons-react'
import React, { memo, FC } from 'react'
import { Link } from 'react-router-dom'
import Match from './Match'
import PredictionsMatch from './Prediction/PredictionMatch'
import { ROUTES } from '@/resources/routes-constants'

interface TournamentProps {
  name: string
  matches: IMatch[]
  isShowPrediction?: boolean
  country?: {
    name: string
    flag: string
    slug: string
  }
  league?: {
    name: string
    flag: string
    slug: string
  }
}

const Tournament: FC<TournamentProps> = ({ name, matches, country, league, isShowPrediction }) => {
  return (
    <div className="mt-4">
      <div className="py-1.5 pl-1.5 border-l-4 border-secondary bg-[#edf2f7] flex items-center justify-between">
        <div className="flex items-center">
          {country && (
            <span className="inline-flex gap-2 items-center">
              <img className="w-4" src={country.flag} alt="" />
              <Link className="text-primary hover:text-red text-xs font-bold" to={`/${country.slug}`}>
                {country.name}
              </Link>
            </span>
          )}
          {league && league.slug !== null && (
            <span className="text-primary leading-4">
              {` » `}
              <Link className="text-primary hover:text-red text-xs font-bold" to={ROUTES.TOURNAMENT_RESULTS.replace(':id', league.slug)}>
                {league.name}
              </Link>
            </span>
          )}
        </div>
        <div className="text-right">
          {league && (
            <>
              <Link className="text-primary hover:text-red text-xs font-bold mr-2" to={ROUTES.TOURNAMENT_STANDINGS.replace(':id', league.slug)}>
                BXH
              </Link>
              <Link className="text-primary hover:text-red text-xs font-bold mr-2" to={ROUTES.TOURNAMENT_FIXTURES.replace(':id', league.slug)}>
                Lịch thi đấu
              </Link>
            </>
          )}
        </div>
      </div>
      {matches.map((item, index) => {
        return isShowPrediction === true ? <PredictionsMatch key={index} match={item} /> : <Match key={index} match={item} />
      })}
    </div>
  )
}

export default memo(Tournament)
