import { getHighestLeagueStanding, getLeagues, getStanding } from '@/resources/api-constants'
import { ROUTES } from '@/resources/routes-constants'
import { useAppDispatch } from '@/store/reducers/store'
import { loadingAction } from '@/store/slice/loading.slice'
import { fetchMetaData } from '@/store/slice/metadata.slice'
import { IHighestLeagueStanding, ILeague, ITeamStanding } from '@/types/app-type'
import { CheckmarkFilled, CloseFilled, SubtractFilled } from '@carbon/icons-react'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link, useLocation, useParams } from 'react-router-dom'

const Standings = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const [isLoadMore, setIsLoadMore] = useState(true)
  const [page, setPage] = useState(1)
  const [leagues, setLeagues] = useState<ILeague[] | null>(null)
  const [teams, setTeams] = useState<ITeamStanding[] | null>(null)
  const [teamsStanding, setTeamsStanding] = useState<IHighestLeagueStanding | null>(null)
  // Page type 1 is National page, 0 is Club page
  const [pageType, setPageType] = useState(1)
  const location = useLocation()

  const fetchMeta = async () => {
    try {
      const slug = (id ?? '').replace('-football', '')
      const type = id?.includes('-football') ? 'country' : 'league'

      dispatch(fetchMetaData({ slug, url: location.pathname, type }))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchMeta()
  }, [])

  const fetchStandings = async (year?: number) => {
    try {
      if (!id) {
        return
      }
      const currentYear = new Date().getFullYear()
      const result = await getStanding({ slug: id, season: year ?? currentYear })
      if (!Array.isArray(result)) {
        return
      }
      if (result.length < 15) {
        setIsLoadMore(false)
      }

      if (teams) {
        setTeams([...teams, ...result])
      } else {
        setTeams(result)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchLeagues = async (page: number) => {
    dispatch(loadingAction.show())
    try {
      if (!id) {
        return
      }
      const result = await getLeagues({ countrySlug: id.includes('-football') ? id.replace('-football', '') : id, countryStandingPage: 1, page })

      if (result.data.length < 15) {
        setIsLoadMore(false)
      }
      if (leagues) {
        setLeagues([...leagues, ...result.data])
      } else {
        setLeagues([...result.data])
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  const fetchHighestLeagueStanding = async () => {
    dispatch(loadingAction.show())
    try {
      if (!id) {
        return
      }
      const currentYear = new Date().getFullYear()
      const result = await getHighestLeagueStanding({ team_slug: id.includes('-football') ? id.replace('-football', '') : id, season: currentYear })
      setTeamsStanding(result)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (!id?.includes('-football')) {
      setPageType(0)
      fetchStandings()
    } else {
      setPageType(1)
      fetchHighestLeagueStanding()
      fetchLeagues(page)
    }
  }, [id])
  return (
    <div>
      <div className="py-2.5 pl-1 my-2.5 bg-[#f9f9f9] border border-[#eee]">
        <h1 className="text-sm font-bold text-red">BẢNG XẾP HẠNG</h1>
      </div>
      {pageType === 0 && teams && (
        <table className="w-full text-center flex sm:table">
          <thead>
            <tr className="bg-[#edf2f7] text-xs [&>th]:p-2 flex flex-col items-start sm:table-row w-[108px] sm:w-auto">
              <th>XH</th>
              <th className="text-left">Đội</th>
              <th>Trận</th>
              <th>Thắng</th>
              <th>Hoà</th>
              <th>Thua</th>
              <th>Bàn thắng</th>
              <th>Bàn thua</th>
              <th>HS</th>
              <th>Điểm</th>
              <th>Phong độ 5 trận</th>
            </tr>
          </thead>
          <tbody className="flex flex-row sm:table-row-group overflow-auto">
            {teams.map((item, index) => {
              return (
                <tr key={index} className="text-xs [&>td]:p-2 [&>td]:w-[80px] sm:[&>td]:w-auto border-b border-[#eee] flex flex-col sm:table-row">
                  <td>{index + 1}</td>
                  <td className="text-left overflow-hidden whitespace-nowrap">{item.team_name}</td>
                  <td>{item.all.played}</td>
                  <td>{item.all.win}</td>
                  <td>{item.all.draw}</td>
                  <td>{item.all.lose}</td>
                  <td>{item.all.goals.for}</td>
                  <td>{item.all.goals.against}</td>
                  <td>{item.goalsDiff}</td>
                  <td>{item.points}</td>
                  <td>
                    <div className="flex">
                      {item.form?.split('').map((item, index) => {
                        return (
                          <span key={index}>
                            {item === 'W' && <CheckmarkFilled className="text-primary" />}
                            {item === 'D' && <SubtractFilled className="text-[#EEE]" />}
                            {item === 'L' && <CloseFilled className="text-red" />}
                          </span>
                        )
                      })}
                      </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {pageType === 1 && leagues && (
        <>
          {teamsStanding && (
            <>
              <div className="pl-2.5 py-1.5 mb-4 border-l-4 border-secondary bg-[#edf2f7] flex items-center justify-between">
                <div>
                  <span className="text-primary hover:text-red text-xs font-bold">{`BXH ${teamsStanding.name} mùa giải ${teamsStanding.season}`}</span>
                </div>
                <div className="clear-both"></div>
              </div>
              <table className="w-full text-center flex sm:table">
                <thead>
                  <tr className="bg-[#edf2f7] text-xs [&>th]:p-2 flex flex-col items-start sm:table-row w-[108px] sm:w-auto">
                    <th>XH</th>
                    <th className="text-left">Đội</th>
                    <th>Trận</th>
                    <th>Thắng</th>
                    <th>Hoà</th>
                    <th>Thua</th>
                    <th>Bàn thắng</th>
                    <th>Bàn thua</th>
                    <th>HS</th>
                    <th>Điểm</th>
                    <th>Phong độ 5 trận</th>
                  </tr>
                </thead>
                <tbody className="flex flex-row sm:table-row-group overflow-auto">
                  {teamsStanding.items.map((item, index) => {
                    return (
                      <tr key={index} className="text-xs [&>td]:p-2 [&>td]:w-[80px] sm:[&>td]:w-auto border-b border-[#eee] flex flex-col sm:table-row">
                        <td>{index + 1}</td>
                        <td className="text-left overflow-hidden whitespace-nowrap">{item.team_name}</td>
                        <td>{item.all.played}</td>
                        <td>{item.all.win}</td>
                        <td>{item.all.draw}</td>
                        <td>{item.all.lose}</td>
                        <td>{item.all.goals.for}</td>
                        <td>{item.all.goals.against}</td>
                        <td>{item.goalsDiff}</td>
                        <td>{item.points}</td>
                        <td>
                          {item.form?.split('').map((item, index) => {
                            return (
                              <div key={index} className="flex">
                                {item === 'W' && <CheckmarkFilled className="text-primary" />}
                                {item === 'D' && <SubtractFilled className="text-[#EEE]" />}
                                {item === 'L' && <CloseFilled className="text-red" />}
                              </div>
                            )
                          })}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </>
          )}
          <div className="mt-4">
            <InfiniteScroll
              style={{
                height: 'unset',
                overflow: 'unset'
              }}
              hasMore={isLoadMore}
              loader={<p>Loading...</p>}
              next={() => {
                setPage((prev) => prev + 1)
                fetchLeagues(page + 1)
              }}
              dataLength={leagues.length}
            >
              <div className="bg-[#edf2f7] text-left text-xs [&>th]:p-2 flex justify-between px-2 py-2.5">
                <span>Giải đấu</span>
                <span>Cập nhật</span>
              </div>
              {leagues.map((item, index) => {
                return (
                  <div key={index} className="text-xs [&>td]:p-2 border-b border-[#eee] px-2 py-2.5 flex justify-between">
                    <Link
                      className="text-primary hover:text-red font-bold flex items-center gap-4"
                      to={ROUTES.TOURNAMENT_STANDINGS.replace(':id', item.slug ?? '')}
                    >
                      <img className="max-w-5" src={item.logo} alt={item.name} />
                      BXH {item.name}
                    </Link>
                    <div>{new Date(item.updated_at).toLocaleDateString()}</div>
                  </div>
                )
              })}
            </InfiniteScroll>
          </div>
        </>
      )}
    </div>
  )
}

export default Standings
