import { getHighestLeagueTopScores, getLeagues, getTopScores } from '@/resources/api-constants'
import { ROUTES } from '@/resources/routes-constants'
import { useAppDispatch } from '@/store/reducers/store'
import { loadingAction } from '@/store/slice/loading.slice'
import { fetchMetaData } from '@/store/slice/metadata.slice'
import { ILeague, ITopScorePlayer, ITopScorePlayerClubResponse, ITopScorePlayerResponse } from '@/types/app-type'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link, useLocation, useParams } from 'react-router-dom'

const TopScores = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const [isLoadMore, setIsLoadMore] = useState(true)
  const [page, setPage] = useState(1)
  // Page type 1 is National page, 0 is Club page
  const [pageType, setPageType] = useState(1)
  const [leagues, setLeagues] = useState<ILeague[] | null>(null)
  const [players, setPlayers] = useState<ITopScorePlayer[] | ITopScorePlayerResponse | ITopScorePlayerClubResponse | null>(null)
  const [topScoreHighest, setTopScoreHighest] = useState<ITopScorePlayer[] | null>(null)
  const location = useLocation()

  const fetchMeta = async () => {
    try {
      const slug = (id ?? '').replace('-football', '')
      const type = id?.includes('-football') ? 'country' : 'league'

      dispatch(fetchMetaData({ slug, url: location.pathname, type }))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchMeta()
  }, [])

  const fetchTopScores = async (year?: number) => {
    try {
      if (!id) {
        return
      }
      const currentYear = new Date().getFullYear()
      const result = await getTopScores({ slug: id, season: year ?? currentYear })
      setPlayers(result)
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  const fetchHighestLeagueStanding = async () => {
    dispatch(loadingAction.show())
    try {
      if (!id) {
        return
      }
      const currentYear = new Date().getFullYear()
      const result = await getHighestLeagueTopScores({ country_slug: id.includes('-football') ? id.replace('-football', '') : id, season: currentYear })
      setTopScoreHighest(result)
      console.log(123)
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  const fetchLeagues = async (page: number) => {
    try {
      if (!id) {
        return
      }
      const result = await getLeagues({ countrySlug: id.includes('-football') ? id.replace('-football', '') : id, countryStandingPage: 1, page })

      if (result.data.length < 15) {
        setIsLoadMore(false)
      }
      if (leagues) {
        setLeagues([...leagues, ...result.data])
      } else {
        setLeagues(result.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }
  useEffect(() => {
    dispatch(loadingAction.show())
    setPage(1)
    if (!id?.includes('-football')) {
      setPageType(0)
      fetchTopScores()
    } else {
      setPageType(1)
      fetchHighestLeagueStanding()
      fetchLeagues(page)
    }
  }, [id])
  console.log(pageType, players)
  return (
    <div>
      <div className="py-2.5 pl-1 my-2.5 bg-[#f9f9f9] border border-[#eee]">
        <h1 className="text-sm font-bold text-red">TOP GHI BÀN BÓNG ĐÁ  MỚI NHẤT</h1>
      </div>
      {pageType === 0 && (
        <>
          {typeof players === 'object' && !Array.isArray(players) && players !== null && (
            <div>
              {Object.entries(players).map((league, index) => {
                return (
                  <>
                    <div className="pl-2.5 py-1.5 mb-4 border-l-4 border-secondary bg-[#edf2f7] flex items-center justify-between">
                      <div>
                        <span className="text-primary hover:text-red text-xs font-bold">{league[0]}</span>
                      </div>
                      <div className="clear-both"></div>
                    </div>
                    <table key={index} className="w-full text-center flex sm:table">
                      <thead>
                        <tr className="bg-[#edf2f7] text-xs [&>th]:p-2 flex flex-col items-start sm:table-row w-[108px] sm:w-auto">
                          <th>XH</th>
                          <th className="text-left">Cầu thủ</th>
                          <th>Bàn thắng</th>
                          <th>Penalty</th>
                        </tr>
                      </thead>
                      {league[1] && Array.isArray(league[1]) && league[1].length > 0 && (
                        <tbody className="flex flex-row sm:table-row-group overflow-auto">
                          {league[1].map((item, index) => {
                            return (
                              <tr key={index} className="text-xs [&>td]:p-2 border-b border-[#eee] flex flex-col sm:table-row">
                                <td>{index + 1}</td>
                                <td className="text-left">
                                  <p>{item.player_name}</p>
                                </td>
                                <td>{item.goals}</td>
                                <td>{item.penalty}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      )}
                    </table>
                  </>
                )
              })}
            </div>
          )}
          {players && Array.isArray(players) && players.length > 0 && (
          <table className="w-full text-center flex sm:table">
            <thead>
              <tr className="bg-[#edf2f7] text-xs [&>th]:p-2 flex flex-col items-start sm:table-row w-[108px] sm:w-auto">
                <th>XH</th>
                <th className="text-left">Cầu thủ</th>
                <th>Bàn thắng</th>
                <th>Penalty</th>
              </tr>
            </thead>
              <tbody className="flex flex-row sm:table-row-group overflow-auto">
                {players.map((item, index) => {
                  return (
                    <tr key={index} className="text-xs [&>td]:p-2 border-b border-[#eee] flex flex-col sm:table-row">
                      <td>{index + 1}</td>
                      <td className="text-left">
                        <p>
                          <strong>{item.player_name}</strong>
                        </p>
                        <p>{item.team_name}</p>
                      </td>
                      <td>{item.goals}</td>
                      <td>{item.penalty}</td>
                    </tr>
                  )
                })}
              </tbody>
          </table>
          )}
        </>
      )}
      {pageType === 1 && leagues && (
        <>
          <table className="w-full text-center flex sm:table">
            <thead>
              <tr className="bg-[#edf2f7] text-xs [&>th]:p-2 flex flex-col items-start sm:table-row w-[108px] sm:w-auto">
                <th>XH</th>
                <th className="text-left">Đội bóng</th>
                <th>Bàn thắng</th>
                <th>Penalty</th>
              </tr>
            </thead>
            {topScoreHighest && topScoreHighest.length > 0 && (
              <tbody className="flex flex-row sm:table-row-group overflow-auto">
                {topScoreHighest.map((item, index) => {
                  return (
                    <tr key={index} className="text-xs [&>td]:p-2 border-b border-[#eee] flex flex-col sm:table-row">
                      <td>{index + 1}</td>
                      <td className="text-left">
                        <p>{item.player_name}</p>
                        <p>{item.team}</p>
                      </td>
                      <td>{item.goals}</td>
                      <td>{item.penalty}</td>
                    </tr>
                  )
                })}
              </tbody>
            )}
          </table>
          <InfiniteScroll
            style={{
              height: 'unset',
              overflow: 'unset'
            }}
            hasMore={isLoadMore}
            loader={<p>Loading...</p>}
            next={() => {
              setPage((prev) => prev + 1)
              fetchLeagues(page + 1)
            }}
            dataLength={leagues.length}
          >
            <div className="bg-[#edf2f7] text-left text-xs [&>th]:p-2 flex justify-between px-2 py-2.5">
              <span>Giải đấu</span>
              <span>Cập nhật</span>
            </div>
            {leagues.map((item, index) => {
              return (
                <div key={index} className="text-xs [&>td]:p-2 border-b border-[#eee] px-2 py-2.5 flex justify-between">
                  <Link
                    className="text-primary hover:text-red font-bold flex items-center gap-4"
                    to={ROUTES.TOURNAMENT_TOP_SCORES.replace(':id', item.slug ?? '')}
                  >
                    <img className="max-w-5" src={item.logo} alt={item.name} />
                    Vua phá lưới {item.name}
                  </Link>
                  <div>{new Date(item.updated_at).toLocaleDateString()}</div>
                </div>
              )
            })}
          </InfiniteScroll>
        </>
      )}
    </div>
  )
}

export default TopScores
