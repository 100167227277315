export const ROUTES = {
  HOMEPAGE_ROUTE: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  LIVE_ROUTE: '/livescore',
  FIXTURES: '/fixtures',
  ANALYTICS: '/analysis',
  PREDICTIONS: '/predictions',
  BETTING_ODDS: '/betting-odds',
  TOP_SCORES: '/top-scorers',
  NATIONAL_TEAMS: '/national-teams',
  RESULTS: '/results',
  CLUB: '/club',
  NEWS: '/news',
  FIFA_STANDINGS: '/fifa-rankings',
  STANDINGS: '/standings',
  STANDINGS_WITH_LEAGUE: '/standings/:id',
  NATIONAL_TOURNAMENT: '/:id',
  TOURNAMENT_RESULTS: '/:id/results',
  TOURNAMENT_LIVESCORES: '/:id/livescore',
  TOURNAMENT_ANALYSIS: '/:id/analysis',
  TOURNAMENT_STANDINGS: '/:id/standings',
  TOURNAMENT_FIXTURES: '/:id/fixtures',
  TOURNAMENT_BETTING_ODDS: '/:id/betting-odds',
  TOURNAMENT_TOP_SCORES: '/:id/top-scorers',
  TOURNAMENT_PREDICTIONS: '/:id/predictions',
  TOURNAMENT_TOURNAMENTS: '/:id/tournaments',
  NATIONAL: '/:id',
  NATIONAL_RESULTS: '/:id/results',
  NATIONAL_LIVESCORES: '/:id/livescore',
  NATIONAL_STANDINGS: '/:id/standings',
  NATIONAL_FIXTURES: '/:id/fixtures',
  NATIONAL_TOURNAMENTS: '/:id/tournaments',
  NATIONAL_ANALYSIS: '/:id/analysis',
  NATIONAL_BETTING_ODDS: '/:id/betting-odds',
  NATIONAL_TOP_SCORES: '/:id/top-scorers',
  NATIONAL_PREDICTIONS: '/:id/predictions',
  NATIONAL_TEAM: '/:id/national-team',
  NATIONAL_TEAM_RESULTS: '/:id/national-team?results',
  NATIONAL_TEAM_LIVESCORES: '/:id/national-team?livescore',
  NATIONAL_TEAM_STANDINGS: '/:id/national-team?standings',
  NATIONAL_TEAM_FIXTURES: '/:id/national-team?fixtures',
  NATIONAL_TEAM_TOP_SCORES: '/:id/national-team?top-scorers',
  NATIONAL_TEAM_BETTING_ODDS: '/:id/national-team?betting-odds',
  CLUB_PAGE: '/:id/club',
  MATCH_DETAIL: '/:id/:date/match',
  MATCH_DETAIL_2ND: '/:id/match'
}
