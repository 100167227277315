import Default from '@/layouts/Default'
import React, { useEffect, useState } from 'react'
import { NavLink, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ROUTES } from '@/resources/routes-constants'
import Result from './components/Result'
import Standings from './components/Standings'
import TopScores from './components/TopScorers'
import Fixtures from './components/Fixtures'
import LiveScore from './components/LiveScore'
import IndexPage from './components/index'
import BettingOddsPage from './components/BettingOdds'
import { useAppDispatch } from '@/store/reducers/store'
import { fetchMetaData } from '@/store/slice/metadata.slice'

const NationalTeam = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const [page, setPage] = useState<string | null>('index')
  const location = useLocation()
  const navigate = useNavigate()

  const fetchMeta = async () => {
    try {
      const slug = (id ?? '').replace('-football', '')
      const type = 'national'

      dispatch(fetchMetaData({ slug, url: location.pathname, type }))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchMeta()
  }, [id])

  const handleSwitchTab = (param: string) => {
    const searchParams: Record<string, any> = new URLSearchParams(window.location.search)
    if (searchParams.has('results')) searchParams.delete('results')
    if (searchParams.has('livescore')) searchParams.delete('livescore')
    if (searchParams.has('standings')) searchParams.delete('standings')
    if (searchParams.has('top-scores')) searchParams.delete('top-scores')
    if (searchParams.has('fixtures')) searchParams.delete('fixtures')
    if (searchParams.has('index')) searchParams.delete('index')
    if (searchParams.has('betting-odds')) searchParams.delete('betting-odds')
    setPage(param)
    searchParams.set(param, '')
    navigate(
      {
        search: searchParams.toString().replace(/=(?=&|$)/gm, '')
      },
      { replace: true }
    )
  }

  useEffect(() => {
    const searchParams: Record<string, any> = new URLSearchParams(window.location.search)
    setPage(searchParams.keys().next().value)
  }, [])
  const tabs = [
    {
      label: 'Thông tin',
      url: ''
    },
    {
      label: 'Kết quả',
      url: 'results'
    },
    {
      label: 'Trực tuyến',
      url: 'livescore'
    },
    {
      label: 'Bảng xếp hạng',
      url: 'standings'
    },
    {
      label: 'Top ghi bàn',
      url: 'top-scores'
    },
    {
      label: 'Lịch thi đấu',
      url: 'fixtures'
    },
    {
      label: 'Kèo bóng đá',
      url: 'betting-odds'
    }
  ]
  return (
    <Default>
      <h1 className="py-1 px-2.5 bg-[#f9f9f9] mb-2.5 border border-[#eee] font-bold text-secondary">Bóng đá Anh</h1>
      <div className="border-b-2 border-secondary mt-2">
        <ul className="text-nowrap whitespace-nowrap overflow-x-auto flex">
          {tabs.map((tab, index) => {
            return (
              <li key={index}>
                <span
                  onClick={() => handleSwitchTab(tab.url)}
                  className={
                    tab.url === page
                      ? 'px-2.5 py-2 text-xs cursor-pointer bg-secondary text-primary hover:text-red mr-1.5 inline-block'
                      : 'px-2.5 py-2 text-xs cursor-pointer bg-[#f0f0f0] text-primary hover:text-red mr-1.5 inline-block'
                  }
                >
                  {tab.label}
                </span>
              </li>
            )
          })}
        </ul>
      </div>
      {page === '' && <IndexPage />}
      {!page && <IndexPage />}
      {page === 'results' && <Result />}
      {page === 'standings' && <Standings />}
      {page === 'top-scores' && <TopScores />}
      {page === 'fixtures' && <Fixtures />}
      {page === 'livescore' && <LiveScore />}
      {page === 'betting-odds' && <BettingOddsPage />}
    </Default>
  )
}

export default NationalTeam
