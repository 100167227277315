import React, { FC, ReactNode } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import { Helmet } from 'react-helmet'
import Sidebar from '@/components/Sidebar'
import { useAppSelector } from '@/store/reducers/store'
import { WatsonHealthRotate_180 } from '@carbon/icons-react'
import AdsLeftRight from '@/assets/images/ads-left-right.gif'
import AdsHeader from '@/assets/images/ads-logo.gif'
import { Toaster } from 'react-hot-toast'

interface DefaultProps {
  children: ReactNode
}

const Default: FC<DefaultProps> = ({ children }) => {
  const { loading } = useAppSelector((state) => state.loading)
  const metadata = useAppSelector((state) => state.metadata)

  return (
    <div>
      {loading.show && (
        <div className="z-10 fixed w-full h-full bg-black/80 flex items-center justify-center">
          <WatsonHealthRotate_180 className="text-white animate-spin w-20 h-20" />
        </div>
      )}
      <Toaster />
      <Helmet>
        <base href="/" />
        <title>{metadata.metadata.meta_title}</title>
        <meta name="keywords" content={metadata.metadata.meta_keywords} />
        <meta name="description" content={metadata.metadata.meta_description} />
        <meta property="og:title" content={metadata.metadata.meta_title} />
        <meta property="og:description" content={metadata.metadata.meta_description} />
        <meta property="og:image" content={metadata.metadata.image} />
        <meta property="og:url" content={metadata.metadata.canonical} />
        <meta property="twitter:title" content={metadata.metadata.meta_title} />
        <meta property="twitter:description" content={metadata.metadata.meta_description} />
        <meta property="twitter:image" content={metadata.metadata.image} />
        <meta property="twitter:card" content={metadata.metadata.image} />
        <link rel="canonical" href={metadata.metadata.canonical} />
      </Helmet>
      <Header />
      <div>
        {/*<div className="fixed top-[190px] right-1/2 mr-[630px]">
          <img className="block" src={AdsLeftRight} alt="" />
          <img className="block" src={AdsLeftRight} alt="" />
          <img className="block" src={AdsLeftRight} alt="" />
        </div>
        <div className="fixed top-[190px] left-1/2 ml-[630px]">
          <img className="block" src={AdsLeftRight} alt="" />
          <img className="block" src={AdsLeftRight} alt="" />
          <img className="block" src={AdsLeftRight} alt="" />
        </div>*/}
        <div className="container mx-auto pb-4">
          <div className="w-[100%] md:w-[75%] xl:w-[70%] md:pr-2 mb-4 md:mb-0 md:float-left">
            {metadata.metadata.content_top &&
              <div className="text-xs p-2.5 bg-[#f6fbff] my-2 content_top" dangerouslySetInnerHTML={{ __html: metadata.metadata.content_top}}></div>
            }
            {children}
            {metadata.metadata.content_bottom &&
              <div className="text-xs p-2.5 bg-[#f6fbff] my-2 content_bottom" dangerouslySetInnerHTML={{ __html: metadata.metadata.content_bottom}}></div>
            }
            {metadata.metadata.content_footer &&
              <div className="text-xs p-2.5 bg-[#f6fbff] my-2 content_footer" dangerouslySetInnerHTML={{ __html: metadata.metadata.content_footer}}></div>
            }
          </div>
          <div className="w-[100%] md:w-[25%] xl:w-[30%] md:float-right">
            <Sidebar />
          </div>
          <div className="clear-both"></div>
        </div>
        {/*<div className="fixed bottom-4 left-1/2 -translate-x-1/2 w-full">
          <img className="block mx-auto" src={AdsHeader} alt="" />
          <img className="block mx-auto" src={AdsHeader} alt="" />
        </div>*/}
      </div>
      <Footer />
    </div>
  )
}

export default Default
