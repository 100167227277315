import { getMetaData, getTeamDetail } from '@/resources/api-constants'
import { ROUTES } from '@/resources/routes-constants'
import { useAppDispatch } from '@/store/reducers/store'
import { loadingAction } from '@/store/slice/loading.slice'
import { fetchMetaData } from '@/store/slice/metadata.slice'
import { ITeamDetail } from '@/types/app-type'
import { Information, InformationFilled } from '@carbon/icons-react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const DetailPage = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const [teamDetail, setTeamDetail] = useState<ITeamDetail | null>(null)
  const fetchData = async () => {
    if (!id) return
    dispatch(loadingAction.show())
    try {
      const result = await getTeamDetail({ team_slug: id })
      setTeamDetail(result)
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      <h2 className="flex items-center gap-1 font-bold text-primary">
        <InformationFilled className="text-red" />
        {teamDetail?.name}: Thông tin mới nhất
      </h2>
      {teamDetail && (
        <table className="text-sm w-full mt-4">
          <tbody>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Tên chính thức</td>
              <td>
                <strong>{teamDetail.name}</strong>
              </td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Tên khác</td>
              <td>{teamDetail.name}</td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Biệt danh</td>
              <td>{teamDetail.name}</td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Năm/Ngày thành lập</td>
              <td>{teamDetail.created_at}</td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Bóng đá quốc gia nào?</td>
              <td>{teamDetail.country}</td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Giải bóng đá VĐQG</td>
              <td>{teamDetail.country}</td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Mùa giải-mùa bóng</td>
              <td>{teamDetail.season}</td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Địa chỉ</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Địa chỉ</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Sân vận động</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Sức chứa sân vận động</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Chủ sở hữu</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Chủ tịch</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Giám đốc bóng đá</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Huấn luyện viên hiện tại</td>
              <td>{teamDetail.coach.name}</td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Ngày sinh HLV</td>
              <td>{teamDetail.coach.date_of_birth}</td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Quốc tịch HLV</td>
              <td>{teamDetail.coach.country}</td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Ngày HLV gia nhập đội</td>
              <td>{teamDetail.coach.created_at}</td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Website</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Email</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Facebook chính thức</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Twitter chính thức</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Instagram chính thức</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Youtube chính thức</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">CLB hay ĐTQG?</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Lứa tuổi</td>
              <td></td>
            </tr>
            <tr className="*:border *:border-[#ccc] *:p-1">
              <td className="w-1/5 font-bold">Giới tính (nam / nữ)</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  )
}

export default DetailPage
