import Default from '@/layouts/Default'
import { getLeagues, getMetaData, getPopularLeagues, getStanding } from '@/resources/api-constants'
import { ROUTES } from '@/resources/routes-constants'
import { useAppDispatch } from '@/store/reducers/store'
import { loadingAction } from '@/store/slice/loading.slice'
import { fetchMetaData } from '@/store/slice/metadata.slice'
import { ILeague, ITeamStanding } from '@/types/app-type'
import { CheckmarkFilled, CloseFilled, SubtractFilled } from '@carbon/icons-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link, NavLink, useLocation, useParams } from 'react-router-dom'

const Standings = () => {
  const { id } = useParams()
  const location = useLocation()
  const [isLoadMore, setIsLoadMore] = useState(true)
  const [leagues, setLeagues] = useState<ILeague[] | null>(null)
  const [popularLeagues, setPopularLeagues] = useState<ILeague[] | null>(null)
  const [currentLeague, setCurrentLeague] = useState<string | null>(null)
  const [teams, setTeams] = useState<ITeamStanding[] | null>(null)
  const [page, setPage] = useState(1)

  const dispatch = useAppDispatch()

  const fetchPopularLeagues = async () => {
    dispatch(loadingAction.show())
    try {
      const result = await getPopularLeagues()
      if(result){
        result.map((league, index) => {
          if (index==0) {
            setCurrentLeague(league.slug)
          }
        })
      }
      setPopularLeagues(result)
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  const fetchLeagues = async (page: number) => {
    dispatch(loadingAction.show())
    try {
      const result = await getLeagues({ page })

      if (result.data.length < 15) {
        setIsLoadMore(false)
      }
      if (leagues) {
        setLeagues([...leagues, ...result.data])
      } else {
        setLeagues([...result.data])
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  const fetchStanding = async () => {
    dispatch(loadingAction.show())
    try {
      if (!currentLeague) {
        return
      }
      const currentYear = new Date().getFullYear()
      const result = await getStanding({ slug: currentLeague, season: currentYear })
      setTeams(result)
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  const fetchMeta = async () => {
    try {
      const slug = id ?? ''
      dispatch(fetchMetaData({ type: 'standings', slug: slug, url: location.pathname }))
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchMeta()
    fetchLeagues(page)
    fetchPopularLeagues()

  }, [])
  useEffect(() => {
    if (currentLeague) {
      fetchStanding()
    }
  }, [currentLeague])
  return (
    <Default>
      <div>
        <div className="py-2.5 pl-1 my-2.5 bg-[#f9f9f9] border border-[#eee]">
          <h1 className="text-sm font-bold text-red">Bảng xếp hạng bóng đá</h1>
        </div>
        <div className="border-b-2 border-secondary mt-2">
          <ul className="text-nowrap whitespace-nowrap overflow-x-auto flex">
            {popularLeagues &&
              popularLeagues.map((league, index) => {

                return (
                  <li key={index}>
                    <span
                      onClick={() => setCurrentLeague(league.slug)}
                      className={
                        league.slug === currentLeague
                          ? 'px-2.5 py-2 text-xs cursor-pointer bg-secondary text-primary hover:text-red mr-1.5 inline-block'
                          : 'px-2.5 py-2 text-xs cursor-pointer bg-[#f0f0f0] text-primary hover:text-red mr-1.5 inline-block'
                      }
                    >
                      {league.name}
                    </span>
                  </li>
                )
              })}
          </ul>
        </div>
        {currentLeague && teams && (
          <table className="w-full text-center flex sm:table">
            <thead>
              <tr className="bg-[#edf2f7] text-xs [&>th]:p-2 flex flex-col items-start sm:table-row w-[108px] sm:w-auto">
                <th>XH</th>
                <th className="text-left">Đội</th>
                <th>Trận</th>
                <th>Thắng</th>
                <th>Hoà</th>
                <th>Thua</th>
                <th>Bàn thắng</th>
                <th>Bàn thua</th>
                <th>HS</th>
                <th>Điểm</th>
                <th>Phong độ 5 trận</th>
              </tr>
            </thead>
            <tbody className="flex flex-row sm:table-row-group overflow-auto">
              {teams.map((item, index) => {
                return (
                  <tr key={index} className="text-xs [&>td]:p-2 [&>td]:w-[80px] sm:[&>td]:w-auto border-b border-[#eee] flex flex-col sm:table-row">
                    <td>{item.rank}</td>
                    <td className="text-left overflow-hidden whitespace-nowrap">{item.team_name}</td>
                    <td>{item.all.played}</td>
                    <td>{item.all.win}</td>
                    <td>{item.all.draw}</td>
                    <td>{item.all.lose}</td>
                    <td>{item.all.goals.for}</td>
                    <td>{item.all.goals.against}</td>
                    <td>{item.goalsDiff}</td>
                    <td>{item.points}</td>
                    <td>
                      <div className="flex">
                      {item.form?.split('').map((item, index) => {
                        return (
                          <span key={index}>
                            {item === 'W' && <CheckmarkFilled className="text-primary" />}
                            {item === 'D' && <SubtractFilled className="text-[#EEE]" />}
                            {item === 'L' && <CloseFilled className="text-red" />}
                          </span>
                        )
                      })}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
        {leagues && (
          <div className="mt-4">
            <InfiniteScroll
              style={{
                height: 'unset',
                overflow: 'unset'
              }}
              hasMore={isLoadMore}
              loader={<p>Loading...</p>}
              next={() => {
                setPage((prev) => prev + 1)
                fetchLeagues(page + 1)
              }}
              dataLength={leagues.length}
            >
              <div className="bg-[#edf2f7] text-left text-xs [&>th]:p-2 flex justify-between px-2 py-2.5">
                <span>Giải đấu</span>
                <span>Cập nhật</span>
              </div>
              {leagues.map((item, index) => {
                return (
                  <div key={index} className="text-xs [&>td]:p-2 border-b border-[#eee] px-2 py-2.5 flex justify-between">
                    <Link
                      className="text-primary hover:text-red font-bold flex items-center gap-4"
                      to={ROUTES.TOURNAMENT_STANDINGS.replace(':id', item.slug ?? '')}
                    >
                      <img className="max-w-5" src={item.logo} alt={item.name} />
                      BXH {item.name}
                    </Link>
                    <div>{new Date(item.updated_at).toLocaleDateString()}</div>
                  </div>
                )
              })}
            </InfiniteScroll>
          </div>
        )}
        {/* <table className="w-full text-center flex sm:table">
          <thead>
            <tr className="bg-[#edf2f7] text-xs [&>th]:p-2 flex flex-col items-start sm:table-row w-[108px] sm:w-auto">
              <th>XH</th>
              <th className="text-left">Đội</th>
              <th>Trận</th>
              <th>Thắng</th>
              <th>Hoà</th>
              <th>Thua</th>
              <th>Bàn thắng</th>
              <th>Bàn thua</th>
              <th>HS</th>
              <th>Điểm</th>
              <th>Phong độ 5 trận</th>
            </tr>
          </thead>
          <tbody className="flex flex-row sm:table-row-group overflow-auto">
            <tr className="text-xs [&>td]:p-2 border-b border-[#eee] flex flex-col sm:table-row">
              <td>1</td>
              <td className="text-left">Arsenal</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td></td>
            </tr>
            <tr className="text-xs [&>td]:p-2 border-b border-[#eee] flex flex-col sm:table-row">
              <td>1</td>
              <td className="text-left">Arsenal</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td></td>
            </tr>
            <tr className="text-xs [&>td]:p-2 border-b border-[#eee] flex flex-col sm:table-row">
              <td>1</td>
              <td className="text-left">Arsenal</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td></td>
            </tr>
            <tr className="text-xs [&>td]:p-2 border-b border-[#eee] flex flex-col sm:table-row">
              <td>1</td>
              <td className="text-left">Arsenal</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td></td>
            </tr>
            <tr className="text-xs [&>td]:p-2 border-b border-[#eee] flex flex-col sm:table-row">
              <td>1</td>
              <td className="text-left">Arsenal</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td></td>
            </tr>
          </tbody>
        </table> */}
      </div>
    </Default>
  )
}

export default Standings
