import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../assets/images/Logo-No-slogan.png'
import { ROUTES } from '@/resources/routes-constants'
import { ChevronDown, Search, User } from '@carbon/icons-react'
import Breadcrumbs from '@/components/Breadcrumbs'
import { getMenus } from '@/resources/api-constants'
import { IAppMenu, IMenu } from '@/types/app-type'
import { useAppDispatch, useAppSelector } from '@/store/reducers/store'
import { loadingAction } from '@/store/slice/loading.slice'
import AdsHeader from '@/assets/images/ads-logo.gif'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { userAction } from '@/store/slice/user.slice'
import toast from 'react-hot-toast'

const Header = () => {
  const [menu, setMenu] = useState<IAppMenu | null>(null)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user.user)
  const navigate = useNavigate()
  const fetchMenu = async () => {
    dispatch(loadingAction.show())
    try {
      const result = await getMenus()
      setMenu(result)
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  const handleSignOut = async () => {
    try {
      dispatch(userAction.setUser(null))
      localStorage.removeItem('access_token')
      toast.success('Logout successful!')
    } catch (error) {
      toast.error('An error occurred.!')
    }
  }

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible)
  }

  useEffect(() => {
    fetchMenu()
  }, [])

  return (
    <div>
      <div className="w-full bg-[#efefef]">
        <div className="container mx-auto">
          <ul className="py-2.5 text-nowrap whitespace-nowrap overflow-x-auto flex">
            {menu?.topbar.items.map((item, index) => {
              return (
                <li key={index} className="px-2.5 py-1.5 text-xs font-bold">
                  <Link className="text-primary hover:text-red" to={item.url}>
                    {item.name}
                  </Link>
                </li>
              )
            })}
            <div className="clear-both"></div>
          </ul>
        </div>
      </div>
      <div className="mx-auto container">
        <div className="my-2.5 flex justify-between items-center">
          <Link className="inline" to={ROUTES.HOMEPAGE_ROUTE}>
            <img className="block w-[206px]" src={Logo} alt="Logo" />
          </Link>
          <img src={AdsHeader} alt="" />
        </div>
      </div>
      <div className="w-full bg-primary border-b-1.5 border-secondary border-b-[3px]">
        <div className="container mx-auto flex md:items-center justify-between">
          <nav className="flex flex-wrap items-center justify-between w-[calc(100%-60px)]">
            <svg
              onClick={toggleMenu}
              xmlns="http://www.w3.org/2000/svg"
              id="menu-button"
              className="h-[44px] w-6 cursor-pointer md:hidden block text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>

            <div className={`w-full flex md:items-center  ${isMenuVisible ? '' : 'hidden'} md:flex`} id="menu">
              <ul className="text-nowrap whitespace-nowrap overflow-x-auto flex flex-col md:flex-row">
                {menu?.main.items.map((item, index) => {
                  return (
                    <li key={index} className="md:border-r md:border-secondary last:border-none">
                      <Link
                        className="p-3 uppercase bg-primary hover:bg-secondary text-white hover:text-primary float-left font-bold text-[13px] inline-block"
                        to={item.url}
                      >
                        {item.name}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </nav>
          <div className="flex items-center gap-2 -z-[0] ">
            <Link className="p-3.5 text-white block ring-1 ring-inset ring-gray-300" to={'/'}>
              <Search />
            </Link>
            <Menu as="div" className="relative inline-block text-left">
              <MenuButton className="p-3.5 text-white inline-flex w-full justify-center gap-x-1.5 text-sm font-semibold text-gray-900 shadow-sm  hover:bg-gray-50">
                <User />
              </MenuButton>

              <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  {user ? (
                    <>
                      <MenuItem>
                        <button className="text-left block w-full px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                          Account settings
                        </button>
                      </MenuItem>
                      <MenuItem>
                        <button
                          onClick={handleSignOut}
                          className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                          Sign out
                        </button>
                      </MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem>
                        <button
                          onClick={() => navigate(ROUTES.LOGIN)}
                          className="text-left block w-full px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                          Sign in
                        </button>
                      </MenuItem>
                      <MenuItem>
                        <button
                          onClick={() => navigate(ROUTES.REGISTER)}
                          className="text-left block w-full px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                          Register
                        </button>
                      </MenuItem>
                    </>
                  )}
                </div>
              </MenuItems>
            </Menu>

          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="flex items-center text-nowrap whitespace-nowrap overflow-x-auto">
          <span className="text-red font-bold text-xs">HOT:</span>
          <ul className="py-1.5">
            {menu?.hot.items.map((item, index) => {
              return (
                <li key={index} className="inline-block px-1.5 text-xs border-r border-[#d6d6d6]">
                  <Link className="text-primary hover:text-red" to={item.url}>
                    {item.name}
                  </Link>
                </li>
              )
            })}
            <div className="clear-both"></div>
          </ul>
        </div>
      </div>
      <div className="container mx-auto">
        <Breadcrumbs />
      </div>
    </div>
  )
}

export default Header
