import Default from '@/layouts/Default'
import React, { useEffect, useState } from 'react'
import { NavLink, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ROUTES } from '@/resources/routes-constants'
import IndexPage from './components/index'
import { useAppDispatch } from '@/store/reducers/store'
import { loadingAction } from '@/store/slice/loading.slice'
import { getTeamDetail } from '@/resources/api-constants'
import { ITeamDetail } from '@/types/app-type'

const ClubPage = () => {
  const { id } = useParams()
  const [page, setPage] = useState<string | null>('index')
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [teamDetail, setTeamDetail] = useState<ITeamDetail | null>(null)

  const fetchData = async () => {
    if (!id) return
    dispatch(loadingAction.show())
    try {
      const result = await getTeamDetail({ team_slug: id })
      setTeamDetail(result)
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleSwitchTab = (param: string) => {
    const searchParams: Record<string, any> = new URLSearchParams(window.location.search)
    setPage(param)
    searchParams.set(param, '')
    navigate(
      {
        search: searchParams.toString().replace(/=(?=&|$)/gm, '')
      },
      { replace: true }
    )
  }

  useEffect(() => {
    const searchParams: Record<string, any> = new URLSearchParams(window.location.search)
    setPage(searchParams.keys().next().value)
  }, [])
  const tabs = [
    {
      label: 'Thông tin',
      url: ROUTES.CLUB_PAGE.replace(':id', String(id))
    },
    {
      label: 'Kết quả',
      url: ROUTES.TOURNAMENT_RESULTS.replace(':id', String(id))
    },
    {
      label: 'Trực tuyến',
      url: ROUTES.TOURNAMENT_LIVESCORES.replace(':id', String(id))
    },
    {
      label: 'Bảng xếp hạng',
      url: ROUTES.TOURNAMENT_STANDINGS.replace(':id', String(id))
    },
    {
      label: 'Top ghi bàn',
      url: ROUTES.TOURNAMENT_TOP_SCORES.replace(':id', String(id))
    },
    {
      label: 'Lịch thi đấu',
      url: ROUTES.TOURNAMENT_FIXTURES.replace(':id', String(id))
    },
    {
      label: 'Kèo bóng đá',
      url: ROUTES.NATIONAL_BETTING_ODDS.replace(':id', String(id))
    }
  ]
  return (
    <Default>
      <h1 className="py-1 px-2.5 bg-[#f9f9f9] mb-2.5 border border-[#eee] font-bold text-secondary">Bóng đá {teamDetail?.name}</h1>
      <div className="border-b-2 border-secondary mt-2">
        <ul className="text-nowrap whitespace-nowrap overflow-x-auto flex">
          {tabs.map((tab, index) => {
            return (
              <li key={index}>
                <NavLink
                  to={tab.url}
                  key={index}
                  end
                  className={({ isActive }) =>
                    isActive
                      ? 'px-2.5 py-2 text-xs cursor-pointer bg-secondary text-primary hover:text-red mr-1.5 inline-block'
                      : 'px-2.5 py-2 text-xs cursor-pointer bg-[#f0f0f0] text-primary hover:text-red mr-1.5 inline-block'
                  }
                >
                  {tab.label}
                </NavLink>
              </li>
            )
          })}
        </ul>
      </div>
      <IndexPage />
    </Default>
  )
}

export default ClubPage
