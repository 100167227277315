import { WorldCup } from '@/assets'
import { TrophyFilled } from '@carbon/icons-react'
import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AdsSidebar from '@/assets/images/ads-sidebar.gif'
import { ILeague, IAppMenu } from '@/types/app-type'
import { getPopularLeagues, searchLeagues, getMenus } from '@/resources/api-constants'
import { ROUTES } from '@/resources/routes-constants'
import { debounce } from 'lodash'


const Sidebar = () => {
  const [leagues, setLeagues] = useState<ILeague[]>([])
  const [searchResult, setSearchResult] = useState<ILeague[]>([])
  const [searchText, setSearchText] = useState('')
  const [menu, setMenu] = useState<IAppMenu | null>(null)

  const fetchData = async () => {
    try {
      const result = await getPopularLeagues()
      setLeagues(result)
      const menus = await getMenus()
      setMenu(menus)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchSearchLeagues = async (value: string) => {
    try {
      if (value.length > 2) {
        const result = await searchLeagues({ keyword: value })
        setSearchResult(result)
      } else {
        setSearchResult([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const debounceDropDown = useCallback(
    debounce((nextValue) => fetchSearchLeagues(nextValue), 1000),
    []
  )

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchText(value)
    debounceDropDown(value)
  }

  return (
    <>
      <div className="hidden md:block mb-4">
        <img className="w-1/2 inline-block" src={AdsSidebar} alt="" />
        <img className="w-1/2 inline-block" src={AdsSidebar} alt="" />
        <img className="w-1/2 inline-block" src={AdsSidebar} alt="" />
        <img className="w-1/2 inline-block" src={AdsSidebar} alt="" />
        <img className="w-1/2 inline-block" src={AdsSidebar} alt="" />
        <img className="w-1/2 inline-block" src={AdsSidebar} alt="" />
        <img className="w-1/2 inline-block" src={AdsSidebar} alt="" />
        <img className="w-1/2 inline-block" src={AdsSidebar} alt="" />
      </div>
      <div className="text-xs bg-[#f0f0f0]">
        <div className="bg-[#dce0e4] py-2 px-1">
          <p className="uppercase font-bold flex items-center">
            <TrophyFilled className="text-red" />
            Giải bóng đá hot nhất
          </p>
        </div>
        <div className="p-1">
          <input
            value={searchText}
            onChange={handleSearch}
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Tìm kiếm"
            required
          />
        </div>
        <ul className="p-1">
          {searchText ? (
            <>
              {searchResult.length > 0 ? (
                searchResult.map((item, index) => {
                  return <Item item={item} key={index} />
                })
              ) : (
                <p>Không tìm thấy kết quả</p>
              )}
            </>
          ) : (
            leagues.map((item, index) => {
              return <Item item={item} key={index} />
            })
          )}
        </ul>
      </div>
      {menu && menu['right-cup'] &&
      <div className="mt-2 text-xs bg-[#f0f0f0]">
        <div className="bg-[#dce0e4] py-2 px-1">
          <p className="uppercase font-bold flex items-center">
            <TrophyFilled className="text-red" />
            Cúp Vô địch Châu lục / Thế giới
          </p>
        </div>
        <ul className="p-3">
          {
            menu['right-cup'].items.map((item, index) => {
              return (<li key={index} className="my-1 inline-block w-[48%]">
                <Link className="inline-flex gap-1 text-primary hover:text-red" to={item.url}>
                  {item.name}
                </Link>
              </li>)
            })
          }
        </ul>
      </div>
      }
      {menu && menu['right-result'] &&
      <div className="mt-2 text-xs bg-[#f0f0f0]">
        <div className="bg-[#dce0e4] py-2 px-1">
          <p className="uppercase font-bold flex items-center">
            <TrophyFilled className="text-red" />
            KẾT QUẢ BÓNG ĐÁ MỚI NHẤT
          </p>
        </div>
        <ul className="p-3">
          {
            menu['right-result'].items.map((item, index) => {
              return (<li key={index} className="my-1 inline-block w-[100%]">
                <Link className="inline-flex gap-1 text-primary hover:text-red" to={item.url}>
                  {item.name}
                </Link>
              </li>)
            })
          }
        </ul>
      </div>
      }
    </>
  )
}

interface ItemProps {
  item: ILeague
}

const Item: FC<ItemProps> = ({ item }) => {
  return (
    <li className="inline-block w-[48%]">
      <Link to={ROUTES.NATIONAL_RESULTS.replace(':id', item.slug)} className="inline-flex gap-1 text-primary hover:text-red">
        <div>
          <img className="w-4" src={item.logo} alt={item.name} />
        </div>
        {item.name}
      </Link>
    </li>
  )
}

export default memo(Sidebar)
