import React, { useEffect, useState } from 'react'
import Default from '@/layouts/Default'
import { Helmet } from 'react-helmet'
import BettingMatch from '@/components/BettingOdd/BettingMatch'
import { getBettingOdds, getBookmakers, getMetaData } from '@/resources/api-constants'
import { getPrevDate, getPrevDateWithYear } from '@/utility/date'
import { useAppDispatch } from '@/store/reducers/store'
import { loadingAction } from '@/store/slice/loading.slice'
import { IBettingOdd, IBookmaker } from '@/types/app-type'
import { useLocation, useParams } from 'react-router-dom'
import { fetchMetaData } from '@/store/slice/metadata.slice'

const BettingOdds: React.FC = () => {
  const [bettingOdds, setBettingOdds] = useState<IBettingOdd[]>([])
  const [bookmakers, setBookmakers] = useState<IBookmaker[]>([])
  const [bookmakerId, setBookmakerId] = useState<number>()
  const numbers = Array.from({ length: 9 }, (_, i) => i)
  const [day, setDay] = useState(0)
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const location = useLocation()

  const fetchMeta = async () => {
    try {
      const slug = id ?? ''

      dispatch(fetchMetaData({ type: 'betting-odds', slug: slug, url: location.pathname }))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchBookmakers()
    fetchMeta()
  }, [day])

  useEffect(() => {
    if (bookmakerId) {
      fetchFixtures()
    }
  }, [day, bookmakerId])

  const fetchFixtures = async () => {
    dispatch(loadingAction.show())
    try {
      const formattedDate = getPrevDateWithYear(day)
      const result = await getBettingOdds({ date: formattedDate, bookmaker_id: bookmakerId })
      setBettingOdds(result.data)
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  const fetchBookmakers = async () => {
    dispatch(loadingAction.show())
    try {
      const result = await getBookmakers()

      if (result.data.length > 0) {
        setBookmakers(result.data)
        setBookmakerId(result.data[0]?.id)
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingAction.hide())
    }
  }

  return (
    <Default>
      <Helmet>
        <title>Kèo bóng đá</title>
      </Helmet>
      <div className="flex items-center text-nowrap whitespace-nowrap overflow-x-auto pb-2.5">
        {numbers.map((number) => {
          return (
            <span key={number}>
              {day === number ? (
                <span className="px-3 py-1 bg-secondary rounded text-primary  hover:cursor-pointer text-xs mr-2">{getPrevDate(number)}</span>
              ) : (
                <span
                  className="px-3 py-1 bg-[#dce0e4] rounded text-primary hover:text-primary hover:bg-secondary hover:cursor-pointer text-xs mr-2"
                  onClick={() => setDay(number)}
                >
                  {getPrevDate(number)}
                </span>
              )}
            </span>
          )
        })}
      </div>

      {bookmakers && (
        <div className="text-right mt-5">
          <select
            name="bookmaker_id"
            className="border-2 border-primary p-2 text-left w-[200px]"
            onChange={(e: any) => {
              setBookmakerId(e.target.value)
            }}
          >
            {Object.entries(bookmakers).map((bookmaker: any) => {
              return (
                <option key={bookmaker[1].id} value={bookmaker[1].id}>
                  {bookmaker[1].name}
                </option>
              )
            })}
          </select>
        </div>
      )}

      {bettingOdds &&
        Object.entries(bettingOdds).map((item) => {
          return <BettingMatch key={item[0]} name={item[0]} bettingOdd={item[1]} />
        })}
    </Default>
  )
}

export default BettingOdds
