import Default from '@/layouts/Default'
import { getMetaData, getTeamsByPopularLeagues } from '@/resources/api-constants'
import { ROUTES } from '@/resources/routes-constants'
import { useAppDispatch } from '@/store/reducers/store'
import { fetchMetaData } from '@/store/slice/metadata.slice'
import { ITeamsByLeague } from '@/types/app-type'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useLocation, useParams } from 'react-router-dom'

const Club = () => {
  const [leagues, setLeagues] = useState<ITeamsByLeague | null>(null)
  const { id } = useParams()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const fetchMeta = async () => {
    try {
      const slug = id ?? ''

      dispatch(fetchMetaData({ type: 'club', slug: slug, url: location.pathname }))
    } catch (error) {
      console.log(error)
    }
  }
  const fetchData = async () => {
    try {
      const result = await getTeamsByPopularLeagues()
      setLeagues(result)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
    fetchMeta()
  }, [])

  return (
    <Default>
      <Helmet>
        <title>CLB bóng đá thế giới: Câu lạc bộ MU, MC, Arsenal, Liverpool & Chelsea</title>
      </Helmet>
      <div className="py-2.5 pl-1 my-2.5 bg-[#f9f9f9] border border-[#eee]">
        <h1 className="text-sm font-bold text-red">CLB bóng đá thế giới: Câu lạc bộ MU, MC, Arsenal, Liverpool & Chelsea</h1>
      </div>
      {leagues &&
        Object.entries(leagues).map((item, index) => {
          return (
            <div key={index} className="mb-4">
              <h2 className="font-bold text-primary pl-1.5 border-l-4 border-red">{item[0]}</h2>
              <ul className="columns-3">
                {item[1].map((team, teamIndex) => {
                  return (
                    <li className="text-xs text-primary" key={teamIndex}>
                      <Link className="inline-flex gap-1 px-2.5" to={ROUTES.CLUB_PAGE.replace(':id', String(team.slug))}>
                        {team.name}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
    </Default>
  )
}

export default Club
