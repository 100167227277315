import React, { memo, FC } from 'react'
import { Link } from 'react-router-dom'
import { IMatch } from '@/types/app-type'
import { ROUTES } from '@/resources/routes-constants'
import { format } from 'date-fns'

interface MatchProps {
  match: IMatch
}

const PredictionMatch: FC<MatchProps> = ({ match }) => {
  return (
    <>
      <div className="flex items-center text-xs py-1.5 border-b border-[#e5e5e5] last-of-type:border-b-0 flex-wrap">
        <div className="w-[15%] text-left color-[#333]">
          <span>{format(new Date(match.date), 'HH:mm')}</span>
        </div>
        <div className="w-[35%] text-right flex justify-end items-center">
          <img className="w-5 mr-2" src={match.teams.away.logo} alt="" />
          <span>{match.teams.away.name}</span>
        </div>
        <div className="w-[40px] text-center">
          <Link className="text-primary hover:text-red text-xs font-bold" to={ROUTES.MATCH_DETAIL.replace(':id', match.slug).replace(':date', match.date_slug)}>
            <span>{match.goals.away}</span>
            <span>-</span>
            <span>{match.goals.home}</span>
          </Link>
        </div>
        <div className="w-[35%] text-left flex justify-start items-center">
          <img className="w-5 mr-2 h-[auto]" src={match.teams.home.logo} alt="" />
          <span>{match.teams.home.name}</span>
        </div>
        <div className="w-[10%] text-right pr-2">
          <button className="pb-1 pl-4 pr-4 btn bg-[#E53935] text-white rounded-[32px]">cược</button>
        </div>
      </div>
      <div className="bg-[#edf2f7] text-center p-2 text-[#656565] font-bold text-xs/[11px] text-red border-b border-[#e5e5e5] row">
        {match.predictions.join(', ')}
      </div>
    </>
  )
}

export default memo(PredictionMatch)
